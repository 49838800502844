ion-alert {
    --background: var(--ion-color-light);

    .alert-title.sc-ion-alert-md {
        color: var(--ion-color-dark-tint);
        font-weight: bold;
    }

    .alert-message.sc-ion-alert-md {
        color: var(--ion-color-dark-tint);

        p {
            line-height: 1.3;
            margin-bottom: 1rem;
        }
    }

    &.bigger {
        --width: 90%;
        --max-width: 90%;
        --max-height: 90%;

        .alert-checkbox-group.sc-ion-alert-md, .alert-radio-group.sc-ion-alert-md {
            max-height: unset;
        }
    }
}
