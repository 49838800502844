@use '../mixins/reset';

ion-button {
    --box-shadow: none;
    --border-radius: 8px;

    min-height: 40px;
    margin: 0;

    &::part(native) {
        text-transform: none;
        font-weight: bold;
        letter-spacing: 0;
        margin: 0;
    }

    &.button-large {
        font-size: 17px;
    }

    &[color='primary'] app-icon {
        --app-icon-color: var(--ion-color-primary-contrast);
    }

    &.no-padding {
        --padding-top: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --padding-start: 0;
    }
}

.edit-btn {
    @include reset.button;

    --edit-button-color: var(--ion-color-background-overlay);

    color: var(--edit-button-color);
    border-bottom: 1px solid var(--edit-button-color);
    padding-bottom: 4px;
    display: inline-block;
    padding-top: 8px;
}

.square-button {
    width: 45px;
    height: 32px;
    --border-radius: 6px !important;
}
