ion-select {
    --select-height: 40px;
    height: var(--select-height);
    min-height: var(--select-height);
    color: var(--ion-color-dark);

    &.custom.custom {
        --border-color: var(--ion-color-dark);
        --highlight-color-focused: var(--ion-color-dark);
        --highlight-color-invalid: var(--ion-color-danger);
        --highlight-color-valid: var(--ion-color-success);
        --border-radius: 8px;

        &::part(label) {
            font-weight: 600;
        }
    }
}
