ion-checkbox {

    /**
     * https://ionicframework.com/docs/api/checkbox#css-custom-properties
     *
     * --checkbox-background	            Background of the checkbox icon
     * --checkbox-background-checked	    Background of the checkbox icon when checked
     * --border-color	                    Border color of the checkbox icon
     * --border-color-checked	            Border color of the checkbox icon when checked
     * --border-radius	                    Border radius of the checkbox icon
     * --border-style	                    Border style of the checkbox icon
     * --border-width	                    Border width of the checkbox icon
     * --checkmark-color	                Color of the checkbox checkmark when checked
     * --checkmark-width	                Stroke width of the checkbox checkmark
     * --size	                            Size of the checkbox icon
     * --transition	                        Transition of the checkbox icon
     */

    --size: 20px;
    --border-radius: 3px;

    &.large {
        --size: 32px;
    }

    &[color="light"] {
        --checkbox-background: rgba(255, 255, 255, 0.24);
        --checkbox-background-checked: rgba(43, 47, 49, 0.24);
        --border-color: rgba(255, 255, 255, 0.48);
        --border-color-checked: var(--ion-color-light);
        --checkmark-color: var(--ion-color-light);
    }

    &[color="primary"] {
        --checkbox-background: transparent;
        --checkbox-background-checked: var(--ion-color-primary);
        --border-color: var(--ion-color-primary);
        --border-color-checked: var(--ion-color-primary);
        --checkmark-color: var(--ion-color-primary-contrast);
    }

    &.in-item {
        margin-top: 0;
        margin-bottom: 0;
    }
}
