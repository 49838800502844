@use 'src/scss/colors';

ion-input.custom.custom {
    --border-color: var(--ion-color-dark);
    --highlight-color-focused: var(--ion-color-dark);
    --highlight-color-invalid: var(--ion-color-danger);
    --highlight-color-valid: var(--ion-color-success);
    --border-radius: 8px;

    .label-text {
        font-weight: 600;
    }
}
