// ------------------------------------------------------------------------------
//      Typography mixins
// ------------------------------------------------------------------------------

@mixin app-regular-font() {
    font-weight: 400;
    @include reset-margins;
    @content;
}

@mixin app-bold-font() {
    font-weight: bold;
    @include reset-margins;
    @content;
}

@mixin app-semi-bold-font() {
    font-weight: 600;
    @include reset-margins;
    @content;
}

@mixin reset-margins {
    margin: 0;
}

@mixin heading-1 {
    @include app-bold-font;
    font-size: 28px;
    line-height: 1.14;
    @content;
}

@mixin heading-2 {
    @include app-bold-font;
    font-size: 32px;
    line-height: 1.25;
    @content;
}

@mixin heading-3 {
    @include app-bold-font;
    font-size: 18px;
    line-height: 1.6;
    @content;
}

@mixin heading-4 {
    @include app-bold-font;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    @content;
}

@mixin heading-5 {
    @include app-semi-bold-font;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    @content;
}

@mixin paragraph {
    @include app-regular-font {
        font-size: var(--font-size-base);
        line-height: 22px;
        letter-spacing: 0.64px;

        @content;
    }
}

@mixin small {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.48px;
    transform: translateY(2px); // Optical vertical text alignment, specific to the used font.
}

@mixin meta {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 4.2px;
    text-transform: uppercase;
    @content;
}

@mixin meta-small {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    transform: translateY(1px);
    @content;
}

@mixin label {
    font-size: 14px;
    font-weight: normal;
    @content;
}

@mixin ellipsis($display: false) {
    @if $display {
        display: $display;
    }

    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
