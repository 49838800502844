@font-face {
    font-family: "Gilroy";
    src: url("/assets/fonts/Gilroy/regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("/assets/fonts/Gilroy/medium.ttf");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("/assets/fonts/Gilroy/semibold.ttf");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url("/assets/fonts/Gilroy/bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}
