@use 'src/scss/mixins/effects';

ion-footer {
    background-color: var(--ion-color-light);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    // Hide overflow so that rounded corners are clipping the content.
    overflow: hidden;
}
