ion-toolbar {
    //--app-toolbar-padding-vertical: 16px;
    --padding-top: var(--ion-padding);
    --padding-bottom: var(--ion-padding);
    --padding-start: var(--ion-padding);
    --padding-end: var(--ion-padding);

    ion-title, h2 {
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
    }
}
