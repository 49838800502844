ion-refresher {
    z-index: 10;
    border-bottom: 1px solid transparent;
    background-clip: content-box;
    transition: background-color 200ms ease-out, border-color 200ms ease-out;

    ion-refresher-content {
        --color: var(--ion-color-light);
    }

    &.refresher-refreshing {
        background-color: rgba(black, 0.2);
        border-color: rgba(white, 0.2);
    }

    .refresher-pulling-icon,
    .refresher-refreshing-icon {
        --ion-text-color: var(--ion-color-light);
    }
}
