@use "sass:map";
@use "sass:color";
@use "sass:list";

// ------------------------------------------------------------------------------
//      Default theme colors
// ------------------------------------------------------------------------------
//
//      Note: This theme is the default theme for Join Program. The colors defined
//            here are used in the application while there is no theme fetched yet
//            from the API. The API will always provide a theme along with a
//            resolved client, even if that is itself the default theme for
//            Join Program. Therefore, this sass map must be kept in sync with the
//            default theme as provided by the API, in order to not have unnecessary
//            color switches for clients that don't define their own theme.
//
$base-colors: (
    primary : #51c796,
    primary-contrast : #ffffff,
    secondary : #712c50,
    secondary-contrast : #ffffff,
    tertiary : #4c434e,
    tertiary-contrast : #ffffff,
    text-dark : #393938,
    text-light : #ffffff,
    background-overlay : #393938,
    background-overlay-contrast : #ffffff,
    success : #51c796,
    success-contrast : #ffffff,
    warning : #FFBC25,
    warning-contrast : #393938,
    error : #bd2a46,
    error-contrast : #ffffff,
    feedback-primary : #FF9900,
    feedback-primary-contrast: #ffffff,
);

/// Fetch a base color value by name.
@function baseColor($name) {
    @return map.get($base-colors, $name)
}

/// Converts the given HEX color value to a RGB values list.
@function hexToRGB($hex) {
    @return color.red($hex), color.green($hex), color.blue($hex)
}

/// Generate theme variables according to Ionic's color scheme. This mixin can be used
/// within a :root selector for any color name, including the Ionic default colors.
/// It will generate all the required color CSS variables for the given HEX color, as
/// well as a color class when the given color name is not part of Ionic's default colors.
@mixin themeVariables($name, $color, $contrast) {
    --ion-color-#{$name}              : #{$color};
    --ion-color-#{$name}-rgb: #{hexToRGB($color)};
    --ion-color-#{$name}-contrast: #{$contrast};
    --ion-color-#{$name}-contrast-rgb: #{hexToRGB($contrast)};
    --ion-color-#{$name}-shade: #{color.adjust($color, $lightness: -10%)};
    --ion-color-#{$name}-tint: #{color.adjust($color, $lightness: 10%)};

    // Also generate a class for the theme as expected by Ionic if required.
    // This is required for all non-standard colors like primary, secondary etc.
    // See https://ionicframework.com/docs/theming/colors for details.
    @if not list.index(primary secondary tertiary success warning danger dark medium light, $name) {
        .ion-color-#{$name} {
            --ion-color-base: var(--ion-color-#{$name});
            --ion-color-base-rgb: var(--ion-color-#{$name}-rgb);
            --ion-color-contrast: var(--ion-color-#{$name}-contrast);
            --ion-color-contrast-rgb: var(--ion-color-#{$name}-contrast-rgb);
            --ion-color-shade: var(--ion-color-#{$name}-shade);
            --ion-color-tint: var(--ion-color-#{$name}-tint);
        }
    }
}

:root {
    @include themeVariables(
        $name: primary,
        $color: baseColor(primary),
        $contrast: baseColor(primary-contrast)
    );

    @include themeVariables(
        $name: secondary,
        $color: baseColor(secondary),
        $contrast: baseColor(secondary-contrast),
    );

    @include themeVariables(
        $name: tertiary,
        $color: baseColor(tertiary),
        $contrast: baseColor(tertiary-contrast),
    );

    @include themeVariables(
        $name: dark,
        $color: baseColor(text-dark),
        $contrast: baseColor(text-light),
    );

    @include themeVariables(
        $name: light,
        $color: baseColor(text-light),
        $contrast: baseColor(text-dark),
    );

    @include themeVariables(
        $name: success,
        $color: baseColor(success),
        $contrast: baseColor(success-contrast),
    );

    @include themeVariables(
        $name: warning,
        $color: baseColor(warning),
        $contrast: baseColor(warning-contrast),
    );

    @include themeVariables(
        $name: danger,
        $color: baseColor(error),
        $contrast: baseColor(error-contrast),
    );

    // Statically defined, since this color is not defined in the API themes:
    @include themeVariables(
        $name: medium,
        $color: #dedede,
        $contrast: baseColor(text-dark),
    );

    // Custom color that is suitable for text rendering over the app's
    // background color or gradient.
    @include themeVariables(
        $name: background-overlay,
        $color: baseColor(background-overlay),
        $contrast: baseColor(background-overlay-contrast),
    );

    // Custom color that is feedback
    @include themeVariables(
        $name: feedback-primary,
        $color: baseColor(feedback-primary),
        $contrast: baseColor(feedback-primary-contrast),
    );

    // ------------------------------------------------------------------------------
    //      The global default text color:
    // ------------------------------------------------------------------------------

    --ion-text-color: var(--ion-color-background-overlay);
    --ion-overlay-background-color: var(--ion-color-background-overlay-contrast);
}
