@font-face {
    font-family: 'icons';
    src: url('/assets/fonts/icomoon/icomoon.eot?s3bv5y');
    src: url('/assets/fonts/icomoon/icomoon.eot?s3bv5y#iefix')   format('embedded-opentype'),
         url('/assets/fonts/icomoon/icomoon.ttf?s3bv5y')         format('truetype'),
         url('/assets/fonts/icomoon/icomoon.woff?s3bv5y')        format('woff'),
         url('/assets/fonts/icomoon/icomoon.svg?s3bv5y#icomoon') format('svg');

    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check-circle:before {
    content: "\e91d";
}

.icon-comments:before {
    content: "\e91e";
}

.icon-dishes:before {
    content: "\e91f";
}

.icon-drinks:before {
    content: "\e920";
}

.icon-euro:before {
    content: "\e921";
}

.icon-warning-octagon:before {
    content: "\e922";
}

.icon-onboard-1:before {
    content: "\e900";
}

.icon-onboard-2:before {
    content: "\e901";
}

.icon-onboard-3:before {
    content: "\e902";
}

.icon-strawberry:before {
    content: "\e903";
}

.icon-add:before {
    content: "\e904";
}

.icon-arrow-back:before {
    content: "\e905";
}

.icon-arrow-down:before {
    content: "\e906";
}

.icon-arrow:before {
    content: "\e907";
}

.icon-bread:before {
    content: "\e908";
}

.icon-canvas:before {
    content: "\e909";
}

.icon-switch-vertical:before {
    content: "\e90a";
}

.icon-circular:before {
    content: "\e90b";
}

.icon-context:before {
    content: "\e90c";
}

.icon-edit:before {
    content: "\e90d";
}

.icon-egg:before {
    content: "\e90e";
}

.icon-footprint:before {
    content: "\e90f";
}

.icon-gluten:before {
    content: "\e910";
}

.icon-heart-outline:before {
    content: "\e911";
}

.icon-heart:before {
    content: "\e912";
}

.icon-innovation:before {
    content: "\e913";
}

.icon-logo:before {
    content: "\e914";
}

.icon-lupine:before {
    content: "\e915";
}

.icon-lactose:before {
    content: "\e916";
}

.icon-mustard:before {
    content: "\e917";
}

.icon-nuts:before {
    content: "\e918";
}

.icon-nutrients:before {
    content: "\e919";
}

.icon-origin:before {
    content: "\e91a";
}

.icon-peanut:before {
    content: "\e91b";
}

.icon-LogoMark:before {
    content: "\e92f";
}

.icon-raw-meat:before {
    content: "\e930";
}

.icon-beef:before {
    content: "\e931";
}

.icon-shellfish-2:before {
    content: "\e932";
}

.icon-seasons:before {
    content: "\e933";
}

.icon-celery:before {
    content: "\e934";
}

.icon-sesame:before {
    content: "\e935";
}

.icon-soy:before {
    content: "\e936";
}

.icon-trash:before {
    content: "\e937";
}

.icon-user-profile:before {
    content: "\e938";
}

.icon-pig:before {
    content: "\e939";
}

.icon-fish:before {
    content: "\e93a";
}

.icon-shellfish:before {
    content: "\e93b";
}

.icon-sulfur:before {
    content: "\e93c";
}

.icon-switch-loop:before {
    content: "\e954";
}

.icon-fat-plus:before {
    content: "\e955";
}

.icon-chevron-down-outline:before {
    content: "\e969";
}

.icon-qr-code:before {
    content: "\e91c";
}
