/// https://ionicframework.com/docs/api/radio
ion-radio {
    --inner-border-radius: 50%;
    --border-radius: 50%;
    --color: var(--ion-color-medium);
    --color-checked: var(--ion-color-light);

    &::part(container) {
        transition: background-color 200ms ease-out;
    }

    .label-text-wrapper {
        font-weight: 600 !important;
    }

    &::part(mark) {
        width: calc(100% - 2 * var(--border-width));
        height: calc(100% - 2 * var(--border-width));
    }
}
