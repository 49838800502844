@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/flex-utils.css";

@import "scss/colors";
@import "scss/fonts";
@import "scss/icomoon";
@import "scss/typography";

@import "scss/ionic-components/ion-button";
@import "scss/ionic-components/ion-alert";
@import "scss/ionic-components/ion-checkbox";
@import "scss/ionic-components/ion-content";
@import "scss/ionic-components/ion-input";
@import "scss/ionic-components/ion-radio";
@import "scss/ionic-components/ion-header";
@import "scss/ionic-components/ion-label";
@import "scss/ionic-components/ion-refresher";
@import "scss/ionic-components/ion-select";
@import "scss/ionic-components/ion-text";
@import "scss/ionic-components/ion-toolbar";
@import "scss/ionic-components/ion-footer";
@import "scss/ionic-components/ion-modal";

:root {
    --ion-padding: 24px;
    --ion-grid-padding-xs: 24px;
    // TODO: Replace the number for the actual height of the menu footer
    --ion-footer-tabs-height: 40px;
}

[hidden] {
    display: none !important;
}

ion-app {
    a {
        text-decoration: none;
    }
}

.d-flex {
    display: flex;
}

.divider {
    width: 24px;
    height: 4px;
    border-radius: 2px;
    background-color: rgba(var(--ion-color-background-overlay-rgb), 0.24);
    margin: 20px 0 40px 0;
}

.input-field {
    margin-bottom: 24px;
    position: relative;

    .overlay-button {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
    }
}

.full-screen-modal {
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
}

.dish-rating-modal {
    --height: auto;
    --width: 100%;
    --border-radius: 0;
    --overflow: visible;
    align-items: end;

    &::part(content) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

.intro-page {
    padding: var(--ion-padding) var(--ion-padding) 0 var(--ion-padding);
}

.max-content-modal::part(content) {
    height: max-content;
}

.bottom-attached-modal {
    --width: 100%;

    &::part(content) {
        position: absolute;
        bottom: 0;
        height: max-content;

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
