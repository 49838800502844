ion-modal {
    --background: var(--ion-color-light);
    --border-radius: 8px;
    --width: calc(100% - var(--ion-padding) - var(--ion-padding));
    --height: calc(100% - var(--ion-padding) - var(--ion-padding));
}

.coffee-bar-selection-modal {
    --height: 420px;
}
