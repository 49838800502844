@mixin button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    &, &:focus {
        outline: none;
    }

    @content;
}

@mixin list($margin: 0, $padding: 0) {
    list-style: none;
    margin: $margin;
    padding: $padding;

    @content;
}
