html.desktop {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212627;

    body {
        max-width: 400px;
        max-height: 1000px;
        overflow: hidden;
        filter: drop-shadow(0 0 15px black);

        ion-app {
            border-radius: 5px;
            overflow: hidden;
        }
    }
}
