@use 'mixins/typography';

:root {
    --font-stack: 'Gilroy', Arial, Helvetica, sans-serif;
    --font-weight-base: 400;
    --font-size-base: 16px;
    --ion-font-family: var(--font-stack);
}

html,
body {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: var(--font-stack);
    font-weight: var(--font-weight-base);
    font-size: var(--font-size-base);
    line-height: 1.5;
    color: var(--ion-text-color);
}

h1 {
    @include typography.heading-1;
}

h2 {
    @include typography.heading-2;
}

h3 {
    @include typography.heading-3;
}

h4 {
    @include typography.heading-4;
}

p {
    @include typography.paragraph;
}

.meta {
    @include typography.meta;
}

.small {
    @include typography.small;
}

.quote {
    font-style: italic;
    line-height: 1.4;
    &::before {
        content: '\201C';
    }
    &::after {
        content: '\201D';
    }
}

.semi-bold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

.small-bold {
    @include typography.small;
    font-weight: 600;
}

.sc-ion-content-md-h {
    @include typography.app-regular-font;
}

.text-center {
    text-align: center !important;
}

.text-wrap {
    white-space: normal !important;
}

.label {
    @include typography.label;
}

.text-error {
    @include typography.app-regular-font;
    list-style: none;
    font-size: 12px;
    padding-left: 18px;
    color: var(--ion-color-danger);
    margin-bottom: var(--ion-padding);
}

.underline-button {
    @include typography.heading-4;
    text-decoration: underline;
}
